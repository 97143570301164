/* eslint-disable no-undef */
import { AxiosResponse } from 'axios';
import {
    RelatedProduct,
    // ProductConfig,
    Product,
    RelatedProviderProduct,
    ProviderProductConfig,
    WarehouseProduct,
} from '@interfaces/ProductRefactor';
import axiosApiConfig from '@/axiosApiConfig';
import { PaginationResponse } from '@/hooks/usePaginationService';
import ShippingType from '@/interfaces/ShippingType';
import ProductImages from '@/interfaces/ProductImages';
import ProductAttachments from '@/interfaces/ProductAttachments';
import ProductConfig from '@/interfaces/ProductConfigRefactor';
import PurchaseFormat from '@/interfaces/PurchaseFormat';
import IEstablishmentProductConfig from '@/interfaces/IEstablishmentProductConfig';
import Optional from '@/types/Optional';
import IEstablishmentProduct from '@/interfaces/IEstablishmentProduct';

/* eslint-disable*/

type StringNumber = string | number;

export async function getProducts(params?: any): Promise<Product[]> {
    const result: any = await axiosApiConfig.get('/products', { params });

    return result.data;
}

export interface IProductManager {
    id: string;
    sku: string;
    nameEs: string;
    nameEn: string;
    price: number;
  }

// eslint-disable-next-line no-undef
export async function getAvailableProducts(params?: any): Promise<IProductManager[]> {
    const result: any = await axiosApiConfig.get('/available_products', { 
        params: {
            ...params,
        },
    });

    return result.data;
}

const formatProviderProducts = (products: any) => {
    if (products.length === 0) return products;
    const relatedProduct: RelatedProduct[] = products.data.map((prod: any) => {
        const {
            providers,
            ...product
        } = prod;
        return {
            ...product,
            // config: {
            //     ...providers[0].provider_products,
            // },
        };
    });

    return relatedProduct;
};

export const getProviderProducts = async (
    id?: StringNumber, pagination: boolean = false, limit?: StringNumber, skip?: StringNumber, params?: any,
): Promise<any> => {
    const result: any = await axiosApiConfig.get('/products', {
        params: {
            scope: 'providers',
            id,
            $limit: limit,
            $skip: skip,
            ...params,
        },
    });

    if (pagination) {
        result.data.data = formatProviderProducts(result.data);
        return result.data;
    }

    result.data = formatProviderProducts(result);
    return result.data;
};

const formatOpRelatedPoducts = (products: any) => {
    if (products.length === 0) return products;
    const relatedProduct: RelatedProduct[] = products.data.map((prod: any) => {
        const {
            operatives,
            price,
            cost,
            ...product
        } = prod;
        return {
            ...product,
            config: {
                // ...operatives[0].operative_products, 
                // ...operatives.operative_products,
            },
        };
    });

    return relatedProduct;
};

export async function getOpRelatedProducts(
    id: StringNumber, pagination: boolean = false, limit?: StringNumber, skip?: StringNumber,
): Promise<any> {
    const result: AxiosResponse<any, any> = await axiosApiConfig.get('/products', {
        params: {
            scope: 'operatives', /* operatives ( Not change ) */
            id,
            $limit: limit,
            $skip: skip,
        },
    });
    if (pagination) {
        result.data.data = formatOpRelatedPoducts(result.data);
        return result.data;
    }

    result.data = formatOpRelatedPoducts(result);
    return result.data;
}

const formatWhRelatedProducts = (products: any) => {
    const relatedProduct: RelatedProduct[] = products.data.map((prod: any) => {
        const {
            warehouses,
            price,
            cost,
            ...product
        } = prod;
        return {
            ...product,
            // config: {
                
            //     // ...warehouses[0].warehouse_products, Regresar después
            //     // ...warehouses.warehouse_products,
            // },
        };
    });

    return relatedProduct;
};

export async function getWhRelatedProducts(
    id: StringNumber, pagination: boolean = false, limit?: StringNumber, skip?: StringNumber,
): Promise<any> {
    const result: AxiosResponse<any, any> = await axiosApiConfig.get('/products', {
        params: {
            scope: 'warehouses', /* warehouses ( Not change ) */
            id,
            $limit: limit,
            $skip: skip,
        },
    });

    if (pagination) {
        result.data.data = formatWhRelatedProducts(result.data);
        return result.data;
    }

    result.data = formatWhRelatedProducts(result);
    return result.data;
}

export async function getProductsPaginate(
    skip: number, limit: number, params?: any,
): Promise<PaginationResponse<Product>> {
    const result: any = await axiosApiConfig.get('/products', {
        params: {
            ...params,
            $skip: skip,
            $limit: limit,
        },
    });
    
    return result.data;
}

export async function getProductByBarcode(barcode: string): Promise<Optional<Product>> {
    const result: any = await axiosApiConfig.get('/products', {
        params: {
            barcode,
        },
    });
    
    return result.data.length ? result.data[0] : null;
}


export async function getOpProductsPaginated(
    skip: number, limit: number, params?: any,
): Promise<PaginationResponse<Product>> {
    const result: any = await axiosApiConfig.get('/products', {
        params: {
            ...params,
            $skip: skip,
            $limit: limit,
        },
    });

    result.data.data = formatOpRelatedPoducts(result.data);
    return result.data;
}

export async function getWhProductsPaginated(
    skip: number, limit: number, params?: any,
): Promise<PaginationResponse<WarehouseProduct>> {
    const result: any = await axiosApiConfig.get('/products', {
        params: {
            ...params,
            $skip: skip,
            $limit: limit,
        },
    });

    result.data.data = formatWhRelatedProducts(result.data);
    return result.data;
}

export async function getWarehouseProducts(
    params?: any,
): Promise<any[]> {
    const result: any = await axiosApiConfig.get('/warehouse_products', {
        params: {
            ...params,
        },
    });

    // result.data = formatWhRelatedProducts(result.data);
    return result.data;
}

interface relationProductRequest {
    entity: 'warehouse' | 'operative' | 'provider',
    id: string | number,
    productId: string | number,
    // eslint-disable-next-line no-undef
    config?: any,
}
export async function relateProduct({
    entity,
    id,
    productId,
    config,
}: relationProductRequest): Promise<void> {
    let endpoint = '';
    if (entity === 'warehouse') {
        endpoint = 'warehouse_products';
    } else if (entity === 'operative') {
        endpoint = 'operative_products';
    } else if (entity === 'provider') {
        endpoint = 'provider_products';
    }
    
    const result: AxiosResponse<any, any> = await axiosApiConfig.post(`/${endpoint}`, {
        ...config,
        [`${entity}Id`]: id,
        productId,
    });
    // TODO: validate status code success or throw error
    console.log('relateProductToOp [result]', result);
    return Promise.resolve();
}

interface unrelateProductRequest {
    entity: 'warehouse' | 'operative' | 'provider',
    id: string | number,
    productIds: string[] | number[],
}
export async function unrelateProducts({ entity, id, productIds }: unrelateProductRequest): Promise<void> {
    let endpoint = '';
    if (entity === 'warehouse') {
        endpoint = 'warehouse_products';
    } else if (entity === 'operative') {
        endpoint = 'operative_products';
    } else if (entity === 'provider') {
        endpoint = 'provider_products';
    }
    console.log('prodIds', productIds);
    const deletedProducts: number[] = productIds.map(prodId => (+prodId));

    const result: AxiosResponse<any, any> = await axiosApiConfig.delete(`/${endpoint}`, {
        params: {
            [`${entity}Id`]: id,
            deletedProducts: JSON.stringify(deletedProducts),
        },
    });
    // TODO: validate status code success or throw error
    console.log('unrelateProduct [result]', result);
    return Promise.resolve();
}

export const editOpRelatedProducts = async (product: ProductConfig): Promise<ProductConfig> => {
    const result = await axiosApiConfig.patch<ProductConfig>('/operative_products', product);
    return result.data;
};

export const editWhRelatedProducts = async (product: ProductConfig): Promise<ProductConfig> => {
    const result = await axiosApiConfig.patch<ProductConfig>('/warehouse_products', product);
    return result.data;
};

export const editPvRelatedProducts = async (product: ProviderProductConfig): Promise<ProviderProductConfig> => {
    const result = await axiosApiConfig.patch<ProviderProductConfig>('/provider_products', product);
    return result.data;
};

export async function getProviderProductsPaginated(
    skip: number, limit: number, params?: any,
): Promise<PaginationResponse<RelatedProviderProduct>> {
    const result: any = await axiosApiConfig.get('/products', {
        params: {
            ...params,
            $skip: skip,
            $limit: limit,
        },
    });

    // const formattedData = await result.data.data.map((d: any) => (
    //     {
    //         id: d.id,
    //         sku: d.sku,
    //         nameEs: d.nameEs,
    //         nameEn: d.nameEn,
    //         descEn: d.descEn,
    //         descEs: d.descEs,
    //         order: d.order,
    //         deleted: d.deleted,
    //         slug: d.slug,
    //         price: d.price,
    //         cost: d.cost,
    //         barCode: d.barcode,
    //         keywords: d.keywords ? d.keywords.split(/\s+/) : [],
    //         stepper: d.stepepr,
    //         useFolio: d.useFolio,
    //         unit: d.unit ? {
    //             id: d.unit.id,
    //             code: d.unit.code,
    //         } : null,
    //         config: d.providers[0] ? {
    //             price: d.providers[0].provider_products.price,
    //             refillTime: d.providers[0].provider_products.refillTime,
    //             productId: d.providers[0].provider_products.productId,
    //             providerId: d.providers[0].provider_products.providerId,
    //         } : null,
    //     }
    // ));

    // result.data.data = formattedData;

    return result.data;
}

// interface getRelatedProductShippingTypesProps {
//     id: ProductConfig['id'],
// }
export async function getRelatedProductShippingTypes(id: number): Promise<any> {
    const result = await axiosApiConfig.get(`/product_shipping_types?warehouseProductId=${id}`);
    return result.data;
}

interface ProductsShippingTypesRequest {
    warehouseProductId: ProductConfig['id'],
    shippingTypesIds: ShippingType['id'][],
}
export async function relateShippingTypes({ warehouseProductId, shippingTypesIds }
    : ProductsShippingTypesRequest): Promise<void> {
    const result = await axiosApiConfig.post('/product_shipping_types_relation', {
        warehouseProductId,
        shippingTypesIds,
    });
    return result.data;
}

export async function unrelateShippingTypes({ warehouseProductId, shippingTypesIds }
    : ProductsShippingTypesRequest): Promise<void> {
    const result = await axiosApiConfig.delete('/product_shipping_types_relation', {
        params: {
            warehouseProductId,
            shippingTypesIds: JSON.stringify(shippingTypesIds),
        },
    });
    return result.data;
}

// eslint-disable-next-line no-undef
export const createProduct = async (data: Partial<Product>)
    : Promise<Product> => {
    const result = await axiosApiConfig.post<any>('/products', {
        ...data,
    });

    if (result.status !== 201) {
        return Promise.reject(result);
    }
    
    return result.data;
};

// eslint-disable-next-line no-undef
export const editProduct = async (productId: any, data: Partial<any>)
    : Promise<Product> => {
    const result = await axiosApiConfig.patch<any>(`/products/${productId}`, {
        ...data,
    });
    return result.data;
};

export const getProductImgs = async (params: ProductImages)
    : Promise<ProductImages> => {
    const result = await axiosApiConfig.get<any>('/product_imgs', {
        ...params,
    });
    return result.data;
};

export const createProductTag = async (data: any)
    : Promise<any> => {
    const result = await axiosApiConfig.post<any>('/product_tags', {
        ...data,
    });
    return result.data;
};

export const editProductTags = async (data: any, productId: string | number)
    : Promise<any> => {
    const result = await axiosApiConfig.patch<any>(`/product_tags/${productId}`, data);
    return result.data;
};

export const createProductCategory = async (data: any)
    : Promise<any> => {
    const result = await axiosApiConfig.post<any>('/product_categories', {
        ...data,
    });
    return result.data;
};

export const editProductCategories = async (data: any, productId: string | number)
    : Promise<any> => {
    const result = await axiosApiConfig.patch<any>(`/product_categories/${productId}`, data);
    return result.data;
};

export const createProductBadge = async (data: any)
    : Promise<any> => {
    const result = await axiosApiConfig.post<any>('/product_badges', {
        ...data,
    });
    return result.data;
};

export const editProductBadges = async (data: any, productId: string | number)
    : Promise<any> => {
    const result = await axiosApiConfig.patch<any>(`/product_badges/${productId}`, data);
    return result.data;
};

export const createRelatedProduct = async (data: any, params?: any)
    : Promise<any> => {
    const result = await axiosApiConfig.post<any>('/related_products', {
        ...data,
    },
    {
        ...params,
    });
    return result.data;
};

export const deleteRelatedProduct = async (id: string | number)
    : Promise<any> => {
    const result = await axiosApiConfig.delete<any>(`/related_products/${id}`);
    return result.data;
};

export async function getRelatedProducts(params?: any): Promise<any[]> {
    const result: any = await axiosApiConfig.get('/related_products', { params });
    return result.data;
}

export const editRelatedProducts = async (data: any, fromId: string | number, params?: any)
    : Promise<any> => {
    const result = await axiosApiConfig.patch<any>(`/related_products/${fromId}`, data, {
        ...params,
    });
    return result.data;
};

export const createProductImgs = async (data: ProductImages[])
    : Promise<any> => {
    const result = await axiosApiConfig.post<any>('/product_imgs', data);
    return result.data;
};

export const createProductAttachments = async (data: ProductAttachments[])
    : Promise<any> => {
    const result = await axiosApiConfig.post<any>('/product_attachments', data);
    return result.data;
};

export const editProductImgs = async (productId: any, data: ProductImages[])
    : Promise<any> => {
    const result = await axiosApiConfig.patch<any>(`/product_imgs/${productId}`, data);
    return result.data;
};

export const editProductAttachments = async (productId: any, data: ProductAttachments[])
    : Promise<any> => {
    const result = await axiosApiConfig.patch<any>(`/product_attachments/${productId}`, data);
    return result.data;
};

export const getProductPurchaseFormats = async (productId: Product['id']): Promise<PurchaseFormat[]> => {
    const result = await axiosApiConfig
        .get('/purchase_formats', {
            params: {
                productId,
            },
        });
    return result.data || [];
};


export async function getFormatEstablishmentProducts(
    params?: any,
): Promise<IEstablishmentProductConfig[]> {
    const result = await axiosApiConfig.get<IEstablishmentProductConfig[]>('/products', {
        params: {
            ...params,
            scope: 'establishments',
        },
    });

    return result.data;
}

export async function getEstablishmentProductsPaginated(
    skip: number, limit: number, params?: any,
): Promise<PaginationResponse<IEstablishmentProductConfig>> {
    const result: any = await axiosApiConfig.get('/products', {
        params: {
            ...params,
            scope: 'establishments',
            $skip: skip,
            $limit: limit,
        },
    });

    return result.data;
}

export async function getEstablishmentProducts(
    params?: any,
): Promise<IEstablishmentProduct[]> {
    const result: any = await axiosApiConfig.get('/establishment_products', {
        params: {
            ...params,
        },
    });

    return result.data;
}

interface IEstablishmentProductDto {
    price?: number;
    tolerance?: Optional<number>;
    productId?: string;
    establishmentId?: string;
}

export async function updateEstablishmentProduct(
    id: string,
    data: IEstablishmentProductDto,
): Promise<void> {
    await axiosApiConfig.patch(`/establishment_products/${id}`, data);
}

export async function deleteEstablishmentProduct(
    id: string,
): Promise<void> {
    await axiosApiConfig.delete(`/establishment_products/${id}`);
}

export async function createEstablishmentProduct(
    data: IEstablishmentProductDto,
): Promise<IEstablishmentProduct> {
    const res = await axiosApiConfig.post<IEstablishmentProduct>('/establishment_products', data);

    return res.data;
}

export const deleteProduct = async (id: Product['id']): Promise<void> => {
    const result = await axiosApiConfig.delete(`/products/${id}`);

    if (result.status !== 200) {
        return Promise.reject(result.data);
    }

    return result.data;
};
  