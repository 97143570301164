import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import { PrivateRoute } from '@/hooks/useAuth';
import EBasicActions from '@/enums/EBasicActions';

const TranslationPage = lazy(() => import('@/containers/Views/Translations/TranslationPage'));

const TranslationRouter = (
  <Route path="translations">
    <Route
      index 
      element={(
        <TranslationPage />
      )} 
    />
  </Route>
);

export default TranslationRouter;
